<template>
  <div class="d-flex flex-column flex-grow-1 mr-2 mb-2 rounded">
    <BaseToolBar title="Administrateur" subtitle="Droits d'accès à l'application QWARK"></BaseToolBar>
    <div>
      <checkbox
        class="w-100"
        v-model="user_active"
        text="Autoriser l'accès à la page collaborateur"
        :errors="feedback.user_active"
        :iconActive="true"
        @change="updateCollaborateur"
      ></checkbox>
      <checkbox
        v-if="user_active && (havePermission('manage_user_roles') || haveRole('admin'))"
        class="w-100 mt-2"
        v-model="qwark_manager"
        text="Autoriser l'accès à QWARK"
        :errors="feedback.qwark_manager"
        :iconActive="true"
        @change="updateCollaborateur"
      ></checkbox>
    </div>
    <div v-if="user_active" class="mt-3 mb-3">
      <div class="d-flex align-items-center"></div>
      <div>
        <modal id="modal_password" title="Changer le mot de passe" btn="Changer">
          <template v-slot:title>
            <div class="text-success" v-if="collaborateur_have_password">Mot de passe déjà créé</div>
            <div class="text-danger" v-if="!collaborateur_have_password">Aucun mot de passe enregsitré</div>
          </template>
          <template v-slot:form>
            <base-form @click="changePassword" :loading="loading" class="p-3 pl-lg-5 pr-lg-5 rounded">
              <BaseIcon name="key" width="50" height="50" class="text-danger"></BaseIcon>
              <base-form-row row>
                <base-input
                  v-model="data3"
                  inputText="Mot de passe"
                  inputType="password"
                  :errors="feedback.password"
                  autocomplete="new-password"
                ></base-input>
              </base-form-row>
              <base-form-row row>
                <base-input
                  class="mb-4"
                  v-model="data4"
                  inputText="Répéter le mot de passe"
                  inputType="password"
                  :errors="feedback.password"
                  autocomplete="new-password"
                ></base-input>
              </base-form-row>
            </base-form>
          </template>
        </modal>
      </div>
    </div>
    <div v-if="qwark_manager && (havePermission('manage_user_roles') || haveRole('admin'))">
      <h6>Rôles</h6>
      <div v-for="role in roles" :key="role.id">
        <div v-if="role.name !== 'admin' || haveRole('admin')" class="d-flex rounded p-1 align-items-center">
          <div v-if="inArray(role.name, collaborateurRoles)" class="d-flex align-items-center">
            <BaseIcon name="unlock" width="16" class="text-success mb-1 mr-2"></BaseIcon>
            <Checkbox id="1" class="mt-0" :checked="true" :iconActive="true" @change="revokeRole(role)"></Checkbox>
            <div class="w-100 mr-1">{{ role.name }}</div>
          </div>
          <div v-else class="d-flex align-items-center">
            <BaseIcon name="lock" width="16" class="text-danger mb-1 mr-2"></BaseIcon>
            <Checkbox id="1" class="mt-0" :checked="false" :iconActive="true" @change="assignRole(role)"></Checkbox>
            <div class="w-100 mr-1">{{ role.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="qwark_manager" v-background-3 class="mt-3 shadow-sm p-3 rounded">
      <h6 class="mb-4">QSSER</h6>
      <base-input
        v-model="objectif_mensuel_animations"
        inputText="Objectif mensuel d'animation(s) SSER"
        :errors="feedback.objectif_mensuel_animations"
      ></base-input>
      <div class="d-flex mt-3">
        <base-button
          class="ml-auto"
          text="Enregistrer"
          icon="save"
          @click="updateCollaborateur"
          :loading="loading"
        ></base-button>
      </div>
    </div>
    <div v-if="haveRole('admin')">
      <base-button
        class="ml-auto btn-danger"
        text="Supprimer le collaborateur"
        icon="trash"
        @click="deleteCollaborateur"
        :loading="loading"
      ></base-button>
    </div>
  </div>
</template>
<script>
import BaseToolBar from "@/components/bases/ToolBar.vue";
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import { errHandler } from "@/helpers.js";
import { mapActions } from "vuex";
import BaseForm from "@/components/bases/Form.vue";
import Checkbox from "@/components/bases/Checkbox.vue";
import BaseInput from "@/components/bases/Input.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseIcon from "@/components/bases/Icon.vue";
import BaseButton from "@/components/bases/Button.vue";
import Modal from "@/components/bases/Modal2.vue";

export default {
  components: { BaseToolBar, Checkbox, BaseInput, BaseFormRow, BaseIcon, BaseForm, Modal, BaseButton },
  data() {
    return {
      loading: false,
      data1: false,
      data2: false,
      feedback: {},
      data3: "",
      data4: "",
      roles: [],
      userSelected: {},
      showPassword: true,
    };
  },
  methods: {
    ...mapActions({
      _assignRole: "collaborateurs/assignRole",
      _revokeRole: "collaborateurs/revokeRole",
      _updateCollaborateur: "collaborateurs/updateCollaborateur",
      _deleteCollaborateur: "collaborateurs/deleteCollaborateur",
      _assignAccueil: "collaborateurs/assignAccueil",
      _revokeAccueil: "collaborateurs/revokeAccueil",
    }),
    updateCollaborateur: function () {
      this.feedback = {};
      this.loading = true;
      this._updateCollaborateur()
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },

    deleteCollaborateur: function () {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      this.feedback = {};
      this.loading = true;
      this._deleteCollaborateur(this.collaborateur)
        .then(() => this.$router.push({ name: "sirh_dashboard" }))
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },

    getRoles: function () {
      if (!this.haveRole("admin") && !this.havePermission("manage_user_roles")) {
        return null;
      }
      this.loading = true;
      this.$http
        .get("/users/roles")
        .then((response) => {
          this.roles = response.data.Roles;
        })
        .finally(() => (this.loading = false));
    },
    assignRole: function (role) {
      this.feedback = {};
      this.loading = true;
      this._assignRole({
        user_id: this.id,
        role: role,
      })
        //.catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    revokeRole: function (role) {
      this.feedback = {};
      this.loading = true;
      this._revokeRole({
        user_id: this.id,
        role: role,
      })
        //.catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    inArray: function (value, array) {
      for (var i = 0; i < array.length; i++) {
        if (array[i].name === value) return true;
      }
      return false;
    },
    changePassword: function () {
      this.loading = true;
      this.feedback = {};

      if (this.data3 === this.data4) {
        this.$http
          .post("/users/password", {
            id: this.id,
            password: this.data3,
          })
          .then(() => (this.collaborateur_have_password = true))
          .catch((error) => (this.feedback = errHandler(error)))
          .finally(() => {
            this.loading = false;
            this.data3 = "";
            this.data4 = "";
          });
      } else {
        alert("les mots de passe saisies ne sont pas identiques");
        this.loading = false;
        return null;
      }
    },
  },
  mounted() {
    this.getRoles();
  },
  computed: {
    ...mapGetters({
      haveRole: "user/haveRole",
      data: "collaborateurs/data",
      accueils: "collaborateurs/accueils",
      havePermission: "user/havePermission",
    }),
    ...mapFields("collaborateurs", [
      "collaborateur",
      "collaborateur.id",
      "collaborateurRoles",
      "collaborateur.user_active",
      "collaborateur.qwark_manager",
      "collaborateur.collaborateur_have_password",
      "collaborateur.objectif_mensuel_animations",
    ]),
  },
};
</script>
<style lang="css"></style>
